export const BASENAME = ''; // don't add '/' at end off BASENAME
export const BASE_URL = '/home';
export const BASE_TITLE = ' | Social ';
export const API_SERVER = process.env.REACT_APP_API_SERVER || 'http://localhost:8000';
export const WS_SERVER = process.env.REACT_APP_WS_SERVER || 'ws://localhost:8000';
export const isLDAP = process.env.REACT_APP_LDAP || false; // set LDAP form
export const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID || '';

// https://codedthemes.com/demos/admin-templates/datta-able/react/docs/
export const CONFIG = {
    layout: 'vertical', // disable on free version
    subLayout: '', // disable on free version
    collapseMenu: false, // mini-menu
    layoutType: 'default', // 'menu-dark', // disable on free version
    navIconColor: false, // disable on free version
    headerBackColor: 'header-default', // disable on free version
    navBackColor: 'navbar-default', // 'navbar-default', // disable on free version
    navBrandColor: 'brand-default', // 'brand-default', // disable on free version
    navBackImage: false, // disable on free version
    rtlLayout: false, // disable on free version
    navFixedLayout: true, // disable on free version
    headerFixedLayout: false, // disable on free version
    boxLayout: false, // disable on free version
    navDropdownIcon: 'style1', // disable on free version
    navListIcon: 'style6', // disable on free version
    navActiveListColor: 'active-default', // disable on free version
    navListTitleColor: 'title-default', // disable on free version
    navListTitleHide: false, // disable on free version
    configBlock: true, // disable on free version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // disable on free version
    layout6BackSize: '', // disable on free version
};
